import { Controller } from "@hotwired/stimulus"

import TransitionGroup from "./transition_group_controller"


/**
 * Define transition classes for an element, much like Vue's transition component.
 * https://vuejs.org/guide/built-ins/transition.html#css-based-transitions
 * 
 * Supported attributes:
 *  - data-transition-enter-from
 *  - data-transition-enter-active
 *  - data-transition-enter-to
 *  - data-transition-leave-from
 *  - data-transition-leave-active
 *  - data-transition-leave-to
 * 
 * Custom attributes:
 *  - data-transition-mirror: Use the leave classes for enter and vice versa, disable with data-transition-mirror="false"
 *  - data-transition-hidden: Class to apply after leave transition, defaults to "hidden"
 * 
 * @example
 *  <div
 *    data-controller="transition"
      data-transition-leave-active="transition-opacity ease-linear duration-700"
      data-transition-leave-from="opacity-100"
      data-transition-leave-to="opacity-0"
 *  ></div>
 */
export default class extends Controller {
  /**
   * Run enter transition
   */
  async enter () {
    const { enterFrom, enterActive, enterTo } = this.states

    // enter-from
    enterFrom.addClasses()
    this.el.hidden = false

    await this.nextFrame()

    // enter-active + enter-to
    enterFrom.removeClasses()
    enterActive.addClasses()
    enterTo.addClasses()

    await this.nextFrame()

    const unmount = () => {
      enterActive.removeClasses()
      enterTo.removeClasses()
    }
    
    // Wait for transition, if present, to end
    // Otherwise, remove classes immediately
    const transitionDuration = parseFloat(getComputedStyle(this.el).transitionDuration)
    if (transitionDuration !== 0)
      this.el.addEventListener("transitionend", unmount, { once: true })
    else
      this.nextFrame(unmount)
  }

  /**
   * Run leave transition
   */
  async leave () {
    const { leaveFrom, leaveActive, leaveTo } = this.states

    // leave-from
    leaveFrom.addClasses()

    await this.nextFrame()
  
    // leave-active + leave-to
    leaveFrom.removeClasses()
    leaveActive.addClasses()
    leaveTo.addClasses()

    await this.nextFrame()

    const unmount = () => {
      leaveActive.removeClasses()
      leaveTo.removeClasses()
      
      this.el.hidden = true
    }
    
    // Wait for transition, if present, to end
    // Otherwise, remove classes immediately
    const transitionDuration = parseFloat(getComputedStyle(this.el).transitionDuration)
    if (transitionDuration !== 0)
      this.el.addEventListener("transitionend", unmount, { once: true })
    else
      this.nextFrame(unmount)
  }

  /**
   * Wait for CSS classes to be applied and rendered
   */
  nextFrame (/** @type {Function | undefined} */ callback) {
    return new Promise((resolve) => {
      window.requestAnimationFrame(() => {
        callback?.()
        resolve()
      })
    })
  }

  /** @type {HTMLElement} */
  get el () {
    return this.element
  }

  get states () {
    const state = (name, mirrorName, defaultValue = []) => {
      // Use leave classes as fallback for enter and vice versa
      const mirrorMode = this.el.dataset.transitionMirror !== 'false'

      // Capitalize first letter
      const capitalizeFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1)

      // Get classes from data attribute
      let classNames = this.el.dataset[`transition${capitalizeFirst(name)}`]?.split(' ')

      // Fallback to mirror classes
      if (mirrorName && mirrorMode)
        classNames ||= this.el.dataset[`transition${capitalizeFirst(mirrorName)}`]?.split(' ')

      // Fallback to default value
      classNames ||= defaultValue

      // Track added classes to remove them later
      let addedClasses = []

      // Track added classes
      const addClasses = () => {
        classNames.forEach(className => {
          // Do not add class if already present
          if (this.el.classList.contains(className) || addedClasses.includes(className))
            return

          this.el.classList.add(className)
          addedClasses.push(className)
        })
      }

      // Remove added classes only
      const removeClasses = () => addedClasses.forEach(className => this.el.classList.remove(className))

      return { addClasses, removeClasses }
    }

    const enterFrom = state('enterFrom', 'leaveTo')
    const enterActive = state('enterActive', 'leaveActive')
    const enterTo = state('enterTo', 'leaveFrom')

    const leaveFrom = state('leaveFrom', 'enterTo')
    const leaveActive = state('leaveActive', 'enterActive')
    const leaveTo = state('leaveTo', 'enterFrom')

    const hidden = state('hidden', null, ['hidden'])

    return {
      enterActive,
      enterFrom,
      enterTo,
      leaveFrom,
      leaveActive,
      leaveTo,
      hidden,
    }
  }
}
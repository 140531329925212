import { Controller } from "@hotwired/stimulus"
import TransitionController from "./transition_controller"

/**
 * Trigger multiple children transitions
 * @example
 *  <div data-controller="transition-group">
 *    <div data-controller="transition"></div>
 *    <div data-controller="transition"></div>
 *    <button data-target="click->transition-group#leave">Close sidebar</button>
 *  </div>
 */
export default class extends Controller {
  /**
   * Run enter transition on all children
   */
  enter () {
    this.transitionControllers.forEach(transition => transition.enter())
  }

  /**
   * Run leave transition on all children
   */
  leave () {
    this.transitionControllers.forEach(transition => transition.leave())
  }

  /** @type {TransitionController[]} */
  get transitionControllers () {
    return Array.from(this.element.querySelectorAll('[data-controller*="transition"]')).map((child) => this.application.getControllerForElementAndIdentifier(child, "transition"))
  }
}